// 引入axios封装
import axios from 'axios'
import { ElMessage, ElMessageBox } from 'element-plus'
import router from '@/router';
import { COMMON_URL } from '../public/IP'

const BASE_URL = COMMON_URL + '/bls/quotation/clothStyle/'

function saveStyleNo(data: any) {
    const addStyleNoUrl = BASE_URL + "saveStyleNo";
    console.log(addStyleNoUrl);
    axios({
        method: "post",
        url: addStyleNoUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded'
        },
        data
    }).then(respone => {
        ElMessageBox.alert(respone.data, '提示信息')
    }).catch(reason => {
        ElMessageBox.alert(reason.data, '提示信息')
    })
}

function queryStyleNoPrice(param: any, result: any, totalQty: any, addData: any) {
    const queryUrl = BASE_URL + "queryStyleNoPrice";
    console.log(queryUrl)
    axios({
        method: "post",
        url: queryUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data: param
    }).then(respone => {
        if (respone.data.errorCode == "111111") {
            ElMessageBox.alert(respone.data.errorMsg, '提示信息')
            return;
        }
        result.value = respone.data.result;
        totalQty.total = result.value.length
        if (respone.data.result.length > 0) {
            addData.id = respone.data.result[0].id
            addData.styleNo = respone.data.result[0].styleNo
            addData.styleName = respone.data.result[0].styleName
            addData.sizeClassNo = respone.data.result[0].sizeClassNo
            addData.colorNo = respone.data.result[0].colorNo
            addData.unit = respone.data.result[0].unit
            addData.price = respone.data.result[0].price
            addData.firstCuttingDate = respone.data.result[0].firstCuttingDate
            addData.designer = respone.data.result[0].designer
            addData.remark = respone.data.result[0].remark
            addData.createTime = respone.data.result[0].createTime
        } else {
            addData.styleNo = ''
            addData.styleName = ''
            addData.sizes = ''
            addData.colorNo = ''
            addData.unit = ''
            addData.price = ''
            addData.firstCuttingDate = ''
            addData.designer = ''
            addData.remark = ''
            addData.createTime=''
        }
    }).catch(reason => {
        console.log(reason);
    })
}

function queryStyleNoWithoutPrice(param: any, result: any, totalQty: any, addData: any) {
    const queryUrl = BASE_URL + "queryStyleNoWithoutPrice";
    console.log(queryUrl)
    axios({
        method: "post",
        url: queryUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data: param
    }).then(respone => {
        if (respone.data.errorCode == "111111") {
            ElMessageBox.alert(respone.data.errorMsg, '提示信息')
            return;
        }
        result.value = respone.data.result;
        totalQty.total = result.value.length
        if (respone.data.result.length > 0) {
            addData.id = respone.data.result[0].id
            addData.styleNo = respone.data.result[0].styleNo
            addData.styleName = respone.data.result[0].styleName
            addData.sizeClassNo = respone.data.result[0].sizeClassNo
            addData.colorNo = respone.data.result[0].colorNo
            addData.unit = respone.data.result[0].unit
            addData.price = respone.data.result[0].price
            addData.firstCuttingDate = respone.data.result[0].firstCuttingDate
            addData.designer = respone.data.result[0].designer
            addData.remark = respone.data.result[0].remark
            addData.createTime = respone.data.result[0].createTime
        } else {
            addData.styleNo = ''
            addData.styleName = ''
            addData.sizes = ''
            addData.colorNo = ''
            addData.unit = ''
            addData.price = ''
            addData.firstCuttingDate = ''
            addData.designer = ''
            addData.remark = ''
            addData.createTime=''
        }
    }).catch(reason => {
        console.log(reason);
    })
}

function queryDesingers(result: any) {
    const queryUrl = BASE_URL + "queryDesigners";
    console.log(queryUrl)
    axios({
        method: "post",
        url: queryUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        }
    }).then(respone => {
        result.value = respone.data
    }).catch(reason => {
        console.log(reason);
    })
}

function queryAllSizeClass(result: any) {
    const queryUrl = BASE_URL + "queryAllSizeClass";
    console.log(queryUrl)
    axios({
        method: "post",
        url: queryUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        }
    }).then(respone => {
        result.value = respone.data
    }).catch(reason => {
        console.log(reason);
    })
}

function queryColor(result: any) {
    const queryUrl = BASE_URL + "queryAllColor";
    console.log(queryUrl)
    axios({
        method: "post",
        url: queryUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        }
    }).then(respone => {
        result.value = respone.data
    }).catch(reason => {
        console.log(reason);
    })
}

function queryLastStyleNo(result: any) {
    const queryUrl = BASE_URL + "queryLastStyleNo";
    console.log(queryUrl)
    axios({
        method: "post",
        url: queryUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        }
    }).then(respone => {
        result.styleNo = respone.data
    }).catch(reason => {
        console.log(reason);
    })
}

function updateStyleNo(param: any) {
    const addStyleNoUrl = BASE_URL + "updateStyleNo";
    console.log(addStyleNoUrl);
    axios({
        method: "post",
        url: addStyleNoUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded'
        },
        data:param
    }).then(respone => {
        ElMessageBox.alert(respone.data, '提示信息')
    }).catch(reason => {
        ElMessageBox.alert(reason.data, '提示信息')
    })
}

function uploadImg(data: any,styleImg: HTMLImageElement) {
    const uploadImgUrl = BASE_URL + "uploadImg";
    console.log(uploadImgUrl);
    axios({
        method: "post",
        url: uploadImgUrl,
        headers: {
            'Content-type': 'multipart/form-data',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data
    }).then(respone => {
        styleImg.src = ''
        styleImg.src = respone.data
        //ElMessageBox.alert(respone.data, '提示信息')
    }).catch(reason => {
        if (reason.data==null) {
            ElMessageBox.alert("文件尺寸过大，请压缩后再上传", '提示信息')
        } else {       
            ElMessageBox.alert(reason.data, '提示信息')        
        }
    })
}

function uploadImg2(data: any,styleImg: HTMLImageElement) {
    const uploadImgUrl = BASE_URL + "uploadImg2";
    console.log(uploadImgUrl);
    axios({
        method: "post",
        url: uploadImgUrl,
        headers: {
            'Content-type': 'multipart/form-data',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data
    }).then(respone => {
        styleImg.src = ''
        styleImg.src = respone.data
       // ElMessageBox.alert(respone.data, '提示信息')
    }).catch(reason => {
        if (reason.data==null) {
            ElMessageBox.alert("文件尺寸过大，请压缩后再上传", '提示信息')
        } else {
            ElMessageBox.alert(reason.data, '提示信息')
        }
    })
}

function uploadPaper(data: any) {
    const uploadImgUrl = BASE_URL + "uploadPaper";
    console.log(uploadImgUrl);
    axios({
        method: "post",
        url: uploadImgUrl,
        headers: {
            'Content-type': 'multipart/form-data',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data
    }).then(respone => {
        ElMessageBox.alert(respone.data, '提示信息')
    }).catch(reason => {
        ElMessageBox.alert(reason.data, '提示信息')
    })
}

function downloadPaper(param: any, fileName: any) {
    const url = BASE_URL + "downloadPaper";
    console.log(url)
    axios({
        method: "post",
        url: url,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data: param,
        responseType: 'blob'
    }).then(respone => {
        if (respone.data.errorCode == "111111") {
            ElMessageBox.alert(respone.data.errorMsg, '提示信息')
            return;
        }

        const url = window.URL.createObjectURL(new Blob([respone.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
    }).catch(reason => {
        console.log(reason);
    })
}

function queryImg(styleNo: any, styleImg: HTMLImageElement) {
    const queryUrl = BASE_URL + "queryImg";
    console.log(queryUrl)
    axios({
        method: "post",
        url: queryUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data: styleNo
    }).then(respone => {
        styleImg.src = respone.data
        //styleImg.src = 'http://43.138.167.176/static/img/test.jpg'
    }).catch(reason => {
        console.log(reason);
    })
}

function queryImg2(styleNo: any, styleImg: HTMLImageElement) {
    const queryUrl = BASE_URL + "queryImg2";
    console.log(queryUrl)
    axios({
        method: "post",
        url: queryUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data: styleNo
    }).then(respone => {
        styleImg.src = respone.data
    }).catch(reason => {
        console.log(reason);
    })
}

function queryPaper(styleNo: any, paperFileName: any) {
    const queryUrl = BASE_URL + "queryPaper";
    console.log(queryUrl)
    axios({
        method: "post",
        url: queryUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data: styleNo
    }).then(respone => {
        paperFileName.value = respone.data
    }).catch(reason => {
        console.log(reason);
    })
}

function deleteStyle(param: any) {
    const queryUrl = BASE_URL + "delete";
    console.log(queryUrl)
    axios({
        method: "post",
        url: queryUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data: param
    }).then(respone => {
        ElMessageBox.alert(respone.data, '提示信息')
    }).catch(reason => {
        console.log(reason);
    })
}

export { saveStyleNo, queryStyleNoWithoutPrice,queryDesingers, queryColor, queryAllSizeClass, queryLastStyleNo, queryStyleNoPrice,updateStyleNo, uploadImg, uploadImg2, uploadPaper, queryImg, queryImg2, queryPaper, downloadPaper, deleteStyle }