// 引入axios封装
import axios from 'axios'
import { ElMessage, ElMessageBox } from 'element-plus'
import { COMMON_URL } from '../../public/IP'

const BASE_URL = COMMON_URL + '/bls/prod/wsProcessStdPrice/'

function save(data: any) {
    const addUrl = BASE_URL + "save";
    console.log(addUrl);
    axios({
        method: "post",
        url: addUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data
    }).then(respone => {
        ElMessageBox.alert(respone.data, '提示信息')
    }).catch(reason => {
        ElMessageBox.alert(reason.data, '提示信息')
    })
}

function query(param: any, result: any, addData: any,totalQty:any,processTypeList:any) {
    const queryUrl = BASE_URL + "query";
    console.log(queryUrl)
    axios({
        method: "post",
        url: queryUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data: param
    }).then(respone => {
        if (respone.data.errorCode == "111111") {
            ElMessageBox.alert(respone.data.errorMsg, '提示信息')
            return;
        }
        result.value = respone.data.result;
        totalQty.total = respone.data.result.length
        if (respone.data.result.length > 0) {
            addData.id = respone.data.result[0].id
            addData.processNo = respone.data.result[0].processNo
            addData.processName = respone.data.result[0].processName
            addData.unit = respone.data.result[0].unit
            addData.price1 = respone.data.result[0].price1
            addData.price2 = respone.data.result[0].price2
            addData.price3 = respone.data.result[0].price3
            addData.price4 = respone.data.result[0].price4
            addData.price5 = respone.data.result[0].price5
            addData.priceRatio = respone.data.result[0].priceRatio
            addData.defaultProcess = respone.data.result[0].defaultProcess
            addData.processType =  processTypeList[respone.data.result[0].processType - 1].label;
            addData.remark = respone.data.result[0].remark
            addData.sizeBigClassNo = respone.data.result[0].sizeBigClassNo
            addData.sn = respone.data.result[0].sn
        } else {
            addData.id = '';
            addData.processNo = '';
            addData.processName ='';
            addData.unit = '';
            addData.price1 ='';
            addData.price2 ='';
            addData.price3 ='';
            addData.price4 ='';
            addData.price5 ='';
            addData.priceRatio ='';
            addData.defaultProcess ='';
            addData.processType ='';
            addData.sizeBigClassNo = '';
            addData.remark = '';
            addData.sn ='';
        }
    }).catch(reason => {
        console.log(reason);
    })
}

function querySizeBigClass(result: any) {
    const queryUrl = BASE_URL + "querySizeBigClass";
    console.log(queryUrl)
    axios({
        method: "post",
        url: queryUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        }
    }).then(respone => {
        result.value = respone.data
    }).catch(reason => {
        console.log(reason);
    })
}

function deletePrice(data: any) {
    const addUrl = BASE_URL + "delete";
    console.log(addUrl);
    axios({
        method: "post",
        url: addUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data
    }).then(respone => {
        ElMessageBox.alert(respone.data, '提示信息')
    }).catch(reason => {
        ElMessageBox.alert(reason.data, '提示信息')
    })
}

export { save, query,querySizeBigClass,deletePrice }