// 引入axios封装
import axios from 'axios'
import { ElMessage, ElMessageBox } from 'element-plus'
import { COMMON_URL } from '../../public/IP'

const BASE_URL = COMMON_URL + '/bls/prod/wsSewingProcess/'

function save(data: any) {
    const addUrl = BASE_URL + "save";
    console.log(addUrl);
    axios({
        method: "post",
        url: addUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data
    }).then(respone => {
        ElMessageBox.alert(respone.data, '提示信息')
    }).catch(reason => {
        ElMessageBox.alert(reason.data, '提示信息')
    })
}

function query(param: any, result: any, addData: any,totalQty:any) {
    const queryUrl = BASE_URL + "query";
    console.log(queryUrl)
    axios({
        method: "post",
        url: queryUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data: param
    }).then(respone => {
        if (respone.data.errorCode == "111111") {
            ElMessageBox.alert(respone.data.errorMsg, '提示信息')
            return;
        }
        result.value = respone.data.result;
        totalQty.total = respone.data.result.length
        if (respone.data.result.length > 0) {
            addData.id = respone.data.result[0].id
            addData.processType =  respone.data.result[0].processType
            addData.processNo = respone.data.result[0].processNo
            addData.processName = respone.data.result[0].processName
            addData.defaultQty = respone.data.result[0].defaultQty
            addData.defaultAdjustAmt = respone.data.result[0].defaultAdjustAmt
            addData.unit = respone.data.result[0].unit
            addData.price = respone.data.result[0].price
            addData.maxPrice = respone.data.result[0].maxPrice
            addData.defaultProcess = respone.data.result[0].defaultProcess
            addData.sizeBigClassNo = respone.data.result[0].sizeBigClassNo
            addData.remark = respone.data.result[0].remark
            addData.sn = respone.data.result[0].sn
        } else {
            addData.id = ''
            addData.processType = ''
            addData.processNo = ''
            addData.processName =''
            addData.unit = ''
            addData.price =''
            addData.remark = ''
            addData.defaultQty = ''
            addData.defaultAdjustAmt = ''
            addData.defaultProcess = ''
            addData.sizeBigClassNo=''
            addData.remark = ''
            addData.sn = ''            
        }
    }).catch(reason => {
        console.log(reason);
    })
}

function querySizeBigClass(result: any) {
    const queryUrl = BASE_URL + "querySizeBigClass";
    console.log(queryUrl)
    axios({
        method: "post",
        url: queryUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        }
    }).then(respone => {
        result.value = respone.data
    }).catch(reason => {
        console.log(reason);
    })
}

function uploadImg(data: any,processImg: HTMLImageElement) {
    const uploadImgUrl = BASE_URL + "uploadImg";
    console.log(uploadImgUrl);
    axios({
        method: "post",
        url: uploadImgUrl,
        headers: {
            'Content-type': 'multipart/form-data',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data
    }).then(respone => {
        processImg.src = ''
        processImg.src = respone.data
    }).catch(reason => {
        if (reason.data==null) {
            ElMessageBox.alert("文件尺寸过大，请压缩后再上传", '提示信息')
        } else {       
            ElMessageBox.alert(reason.data, '提示信息')        
        }
    })
}

function queryImg(processNo: any, processImg: HTMLImageElement) {
    const queryUrl = BASE_URL + "queryImg";
    console.log(queryUrl)
    axios({
        method: "post",
        url: queryUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data: processNo
    }).then(respone => {
        processImg.src = respone.data
    }).catch(reason => {
        console.log(reason);
    })
}

function queryProcessType(processTypeList: any) {
    const queryUrl = BASE_URL + "queryProcessType";
    console.log(queryUrl)
    axios({
        method: "post",
        url: queryUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        }
    }).then(respone => {
        processTypeList.value = respone.data
    }).catch(reason => {
        console.log(reason);
    })
}


function deletePrice(data: any) {
    const addUrl = BASE_URL + "delete";
    console.log(addUrl);
    axios({
        method: "post",
        url: addUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data
    }).then(respone => {
        ElMessageBox.alert(respone.data, '提示信息')
    }).catch(reason => {
        ElMessageBox.alert(reason.data, '提示信息')
    })
}

export { save, query,querySizeBigClass,queryProcessType,uploadImg,queryImg,deletePrice }