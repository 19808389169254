import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import SystemAuditView from '../views/system/AuditView.vue'
import LoginView from '../views/system/LoginView.vue'
import LoadingServiceView from '../views/system/LoadingServiceView.vue'
import ExchangeView from '../views/exchange/ExchangeView.vue'

import SystemTenantView from '../views/system/SystemTenantView.vue'
import UserMgtView from '../views/system/UserMgtView.vue'
import PrivilegesMgtView from '../views/system/PrivilegesMgtView.vue'
import ChangePasswordView from '../views/system/ChangePasswordView.vue'
import StoreView from '../views/system/StoreView.vue'
import UpdateSystemCookieView from '../views/system/UpdateSystemCookieView.vue'

import StyleColorView from '../views/design/StyleColorView.vue'
import StyleSizeView from '../views/design/StyleSizeView.vue'
import ClothStyleView from '../views/design/ClothStyleView.vue'
import ClothOemStyleView from '../views/design/ClothOemStyleView.vue'
import ClothStoreView from '../views/financial/ClothStoreView.vue'
import ClothView from '../views/financial/ClothView.vue'
import StyleBomView from '../views/financial/StyleBomView.vue'
import ClothPoView from '../views/purchasing/ClothPoView.vue'
import ClothPoEditView from '../views/purchasing/ClothPoEditView.vue'
import ClothEdgingQueryView from '../views/purchasing/ClothEdgingQueryView.vue'
import ClothPoListingView from '../views/purchasing/ClothPoListView.vue'
import ClothPoSumView from '../views/purchasing/ClothPoSumView.vue'
import CalculatingDesignFeeView from '../views/financial/CalculatingDesignFeeView.vue'

import StorageInView from '../views/inventory/StorageInView.vue'
import StorageInQueryView from '../views/inventory/StorageInQueryView.vue'
import StorageOutView from '../views/inventory/StorageOutView.vue'
import StorageOutQueryView from '../views/inventory/StorageOutQueryView.vue'
import StorageQueryView from '../views/inventory/StorageQueryView.vue'

import FactoryView from '../views/base/FactoryView.vue'
import DepartmentView from '../views/base/DepartmentView.vue'
import EmployeeView from '../views/base/EmployeeView.vue'
import EmployeePositionView from '../views/base/EmployeePositionView.vue'

import AttendanceView from '../views/attendance/AttendanceView.vue'
import AllowanceView from '../views/salary/AllowanceView.vue'
import PieceRateView from '../views/salary/PieceRateView.vue'
import SalaryView from '../views/salary/SalaryView.vue'

import SheInOrderView from '../views/order/SheInOrderView.vue'
import SheInObmOrderView from '../views/order/SheInObmOrderView.vue'
import TemuOrderView from '../views/order/TemuOrderView.vue'
import TemuPreparingOrderView from '../views/order/TemuPreparingOrderView.vue'
import ProcessStdPriceView from '../views/prod/price/ProcessStdPriceView.vue'
import ProcessPriceBomView from '../views/prod/price/ProcessPriceBomView.vue'
import CuttingBillView from '../views/prod/cuttingBed/CuttingBillView.vue'
import CuttingBillOemView from '../views/prod/cuttingBed/CuttingBillOemView.vue'
import OrderForCuttingView from '../views/prod/cuttingBed/OrderForCuttingView.vue'
import CuttingBillHelpView from '../views/prod/cuttingBed/CuttingBillHelpView.vue'
import CuttingBillQueryView from '../views/prod/cuttingBed/CuttingBillQueryView.vue'
import CuttingBillSumView from '../views/prod/cuttingBed/CuttingBillSumView.vue'
import CuttingBedDataView from '../views/prod/cuttingBed/CuttingBedDataView.vue'
import StyleMakingStatusClosingView from '../views/prod/monitorView/StyleMakingStatusClosingView.vue'
import ProdPlanView from '../views/prod/plan/ProdPlanView.vue'
import SalesActivityView from '../views/prod/plan/SalesActivityView.vue'
import ImportantActivityView from '../views/prod/plan/ImportantActivityView.vue'
import StyleImgView from '../views/design/StyleImgView.vue'
import ProdScheduleView from '../views/prod/plan/ProdScheduleView.vue'
import ProdSchedulingView from '../views/prod/scheduling/ProdSchedulingView.vue'
import ProdSewingTransferView from '../views/prod/transfer/ProdSewingTransferView.vue'
import ProdOutsideTransferView from '../views/prod/transfer/ProdOutsideTransferView.vue'
import ProdCheckingReceiveView from '../views/prod/receive/ProdCheckingReceiveView.vue'
import ProdOutsideReceiveView from '../views/prod/receive/ProdOutsideReceiveView.vue'
import ProdCuttingBedView from '../views/prod/monitorView/ProdCuttingBedView.vue'
import ProdOrderView from '../views/prod/monitorView/ProdOrderView.vue'
import CuttingClothLackingView from '../views/prod/monitorView/CuttingClothLackingView.vue'
import WorkshopMakingView from '../views/prod/monitorView/WorkshopMakingView.vue'
import OrderCutView from '../views/prod/monitorView/OrderCutView.vue'
import PieceRateWageQueryView from '../views/prod/pieceRate/PieceRateWageQueryView.vue'
import PieceRateWageSumView from '../views/prod/pieceRate/PieceRateWageSumView.vue'
import ChangeQtyView from '../views/prod/pieceRate/ChangeQtyView.vue'

import StylePriceView from '../views/settling/StylePriceView.vue'
import StyleCuttingView from '../views/settling/StyleCuttingView.vue'
import StyleProfitView from '../views/settling/StyleProfitView.vue'
import BeltIroningFeeSettlingView from '../views/settling/BeltIroningFeeSettlingView.vue'
import ButtonSewingFeeSettlingView from '../views/settling/ButtonSewingFeeSettlingView.vue'
import OrderMgtView from '../views/order/OrderMgtView.vue'
import OrderQueryView from '../views/order/OrderQueryView.vue'
import OrderClothPreparedView from '../views/order/OrderClothPreparedView.vue'
import ModifySheInOrderView from '../views/order/ModifySheInOrderView.vue'

import FactoryStdCostView from '../views/quotation/FactoryStdCostView.vue'
import TechStdCostView from '../views/quotation/TechStdCostView.vue'
import AccessoryStdCostView from '../views/quotation/AccessoryStdCostView.vue'
import ProcessStdCostView from '../views/quotation/ProcessStdCostView.vue'
import TechBomView from '../views/quotation/TechBomView.vue'
import ProcessBomView from '../views/quotation/ProcessBomView.vue'
import AccessoryBomView from '../views/quotation/AccessoryBomView.vue'
import StyleCostTryCalculatingView from '../views/quotation/StyleCostTryCalculatingView.vue'

import PdfViewer from '../views/report/PdfViewer.vue'
import SalesDataView from '../views/salesData/SalesDataView.vue'
import AmzSalesOrderView from '../views/amazon/order/SalesOrderView.vue'
import AmzSalesOrderTimeAnalyserView from '../views/amazon/order/SalesOrderTimeAnalyserView.vue'

import CustomerView from '../views/customer/CustomerView.vue'
import OrderCostView from '../views/cost/OrderCostView.vue'
import ActivityCostAnalysisView from '../views/cost/ActivityCostAnalysisView.vue'
import ActivityDiscountCostAnalysisView from '../views/cost/ActivityDiscountCostAnalysisView.vue'
import OemSettlingView from '../views/settling/OemSettlingView.vue'

import SewingProcessTypeView from '../views/prod/sewingPrice/SewingProcessTypeView.vue'
import SewingProcessView from '../views/prod/sewingPrice/SewingProcessView.vue'
import SewingProcessBomView from '../views/prod/sewingPrice/SewingProcessBomView.vue'
import SewingProcessPriceCheckView from '../views/prod/sewingPrice/SewingProcessPriceCheckView.vue'

import PayCycleView from '../views/params/PayCycleView.vue'
import StylePrefixView from '../views/design/StylePrefixView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'loginView',
    component: LoginView
  },
  {
    path: '/homeView',
    name: 'homeView',
    component: HomeView
  },
  {
    path: '/systemTenantView',
    name: 'systemTenantView',
    component: SystemTenantView
  },
  {
    path: '/userMgtView',
    name: 'userMgtView',
    component: UserMgtView
  },
  {
    path: '/PrivilegesMgtView',
    name: 'PrivilegesMgtView',
    component: PrivilegesMgtView
  },
  {
    path: '/changePasswordView',
    name: 'changePasswordView',
    component: ChangePasswordView
  },
  {
    path: '/loadingServiceView',
    name: 'loadingServiceView',
    component: LoadingServiceView
  },
  {
    path: '/exchangeView',
    name: 'exchangeView',
    component: ExchangeView
  },
  {
    path: '/storeView',
    name: 'storeView',
    component: StoreView
  },
  {
    path: '/updateSystemCookieView',
    name: 'updateSystemCookieView',
    component: UpdateSystemCookieView
  },
  {
    path: '/styleColorView',
    name: 'styleColorView',
    component: StyleColorView
  },
  {
    path: '/styleSizeView',
    name: 'styleSizeView',
    component: StyleSizeView
  },
  {
    path: '/clothStyleView',
    name: 'clothStyleView',
    component: ClothStyleView
  },
  {
    path: '/clothOemStyleView',
    name: 'clothOemStyleView',
    component: ClothOemStyleView
  },
  {
    path: '/calculatingDesignFeeView',
    name: 'calculatingDesignFeeView',
    component: CalculatingDesignFeeView
  },
  {
    path: '/clothStoreView',
    name: 'clothStoreView',
    component: ClothStoreView
  },
  {
    path: '/clothView',
    name: 'clothView',
    component: ClothView
  },
  {
    path: '/styleBomView',
    name: 'styleBomView',
    component: StyleBomView
  },
  {
    path: '/clothPoView',
    name: 'clothPoView',
    component: ClothPoView
  },
  {
    path: '/clothPoEditView',
    name: 'clothPoEditView',
    component: ClothPoEditView
  },
  {
    path: '/clothEdgingQueryView',
    name: 'clothEdgingQueryView',
    component: ClothEdgingQueryView
  },
  {
    path: '/clothPoListingView',
    name: 'clothPoListingView',
    component: ClothPoListingView
  },
  {
    path: '/clothPoSumView',
    name: 'clothPoSumView',
    component: ClothPoSumView
  },
  {
    path: '/stylePriceView',
    name: 'stylePriceView',
    component: StylePriceView
  },
  {
    path: '/storageInView',
    name: 'storageInView',
    component: StorageInView
  },
  {
    path: '/storageInQueryView',
    name: 'storageInQueryView',
    component: StorageInQueryView
  },
  {
    path: '/storageOutView',
    name: 'storageOutView',
    component: StorageOutView
  },
  {
    path: '/storageOutQueryView',
    name: 'storageOutQueryView',
    component: StorageOutQueryView
  },
  {
    path: '/storageQueryView',
    name: 'storageQueryView',
    component: StorageQueryView
  },
  {
    path: '/styleProfitView',
    name: 'styleProfitView',
    component: StyleProfitView
  },
  {
    path: '/styleCuttingView',
    name: 'styleCuttingView',
    component: StyleCuttingView
  },
  {
    path: '/factoryView',
    name: 'factoryView',
    component: FactoryView
  },
  {
    path: '/departmentView',
    name: 'departmentView',
    component: DepartmentView
  },
  {
    path: '/employeeView',
    name: 'employeeView',
    component: EmployeeView
  },
  {
    path: '/employeePositionView',
    name: 'employeePositionView',
    component: EmployeePositionView
  },
  {
    path: '/allowanceView',
    name: 'allowanceView',
    component: AllowanceView
  },
  {
    path: '/pieceRateView',
    name: 'pieceRateView',
    component: PieceRateView
  },
  {
    path: '/attendanceView',
    name: 'attendanceView',
    component: AttendanceView
  },
  {
    path: '/salaryView',
    name: 'salaryView',
    component: SalaryView
  },
  {
    path: '/orderMgtView',
    name: 'orderMgtView',
    component: OrderMgtView
  },
  {
    path: '/orderQueryView',
    name: 'orderQueryView',
    component: OrderQueryView
  },
  {
    path: '/orderClothPreparedView',
    name: 'orderClothPreparedView',
    component: OrderClothPreparedView
  },
  {
    path: '/sheInOrderView',
    name: 'sheInOrderView',
    component: SheInOrderView
  },
  {
    path: '/sheInObmOrderView',
    name: 'sheInObmOrderView',
    component: SheInObmOrderView
  },
  {
    path: '/temuOrderView',
    name: 'temuOrderView',
    component: TemuOrderView
  },
  {
    path: '/temuPreparingOrderView',
    name: 'temuPreparingOrderView',
    component: TemuPreparingOrderView
  },
  {
    path: '/modifySheInOrderView',
    name: 'modifySheInOrderView',
    component: ModifySheInOrderView
  },
  {
    path: '/cuttingBillView',
    name: 'cuttingBillView',
    component: CuttingBillView,
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/cuttingBillOemView',
    name: 'cuttingBillOemView',
    component: CuttingBillOemView
  },
  {
    path: '/orderForCuttingView',
    name: 'orderForCuttingView',
    component: OrderForCuttingView
  },
  {
    path: '/cuttingBillHelpView',
    name: 'cuttingBillHelpView',
    component: CuttingBillHelpView
  },
  {
    path: '/cuttingBillQueryView',
    name: 'cuttingBillQueryView',
    component: CuttingBillQueryView
  },
  {
    path: '/cuttingBillSumView',
    name: 'cuttingBillSumView',
    component: CuttingBillSumView
  },
  {
    path: '/cuttingBedDataView',
    name: 'cuttingBedDataView',
    component: CuttingBedDataView
  },
  {
    path: '/styleMakingStatusClosingView',
    name: 'styleMakingStatusClosingView',
    component: StyleMakingStatusClosingView
  },
  {
    path: '/prodPlanView',
    name: 'prodPlanView',
    component: ProdPlanView
  },
  {
    path: '/salesActivityView',
    name: 'salesActivityView',
    component: SalesActivityView
  },
  {
    path: '/importantActivityView',
    name: 'importantActivityView',
    component: ImportantActivityView
  },
  {
    path: '/styleImgView',
    name: 'styleImgView',
    component: StyleImgView
  },
  {
    path: '/prodScheduleView',
    name: 'prodScheduleView',
    component: ProdScheduleView
  },
  {
    path: '/prodSchedulingView',
    name: 'prodSchedulingView',
    component: ProdSchedulingView
  },
  {
    path: '/prodSewingTransferView',
    name: 'prodSewingTransferView',
    component: ProdSewingTransferView
  },
  {
    path: '/prodOutsideTransferView',
    name: 'prodOutsideTransferView',
    component: ProdOutsideTransferView
  },
  {
    path: '/prodCheckingReceiveView',
    name: 'prodCheckingReceiveView',
    component: ProdCheckingReceiveView
  },
  {
    path: '/prodOutsideReceiveView',
    name: 'prodOutsideReceiveView',
    component: ProdOutsideReceiveView
  },
  {
    path: '/prodCuttingBedView',
    name: 'prodCuttingBedView',
    component: ProdCuttingBedView
  },
  {
    path: '/prodOrderView',
    name: 'prodOrderView',
    component: ProdOrderView
  },
  {
    path: '/cuttingClothLackingView',
    name: 'cuttingClothLackingView',
    component: CuttingClothLackingView
  },
  {
    path: '/workshopMakingView',
    name: 'workshopMakingView',
    component: WorkshopMakingView
  },
  {
    path: '/orderCutView',
    name: 'orderCutView',
    component: OrderCutView
  },

  {
    path: '/beltIroningFeeSettlingView',
    name: 'beltIroningFeeSettlingView',
    component: BeltIroningFeeSettlingView
  },
  {
    path: '/buttonSewingFeeSettlingView',
    name: 'buttonSewingFeeSettlingView',
    component: ButtonSewingFeeSettlingView
  },
  {
    path: '/factoryStdCostView',
    name: 'factoryStdCostView',
    component: FactoryStdCostView
  },
  {
    path: '/techStdCostView',
    name: 'techStdCostView',
    component: TechStdCostView
  },
  {
    path: '/processStdCostView',
    name: 'processStdCostView',
    component: ProcessStdCostView
  },
  {
    path: '/accessoryStdCostView',
    name: 'accessoryStdCostView',
    component: AccessoryStdCostView
  },
  {
    path: '/techBomView',
    name: 'techBomView',
    component: TechBomView
  },
  {
    path: '/processBomView',
    name: 'processBomView',
    component: ProcessBomView
  },
  {
    path: '/accessoryBomView',
    name: 'accessoryBomView',
    component: AccessoryBomView
  },
  {
    path: '/costTryCalculatingView',
    name: 'costTryCalculatingView',
    component: StyleCostTryCalculatingView
  },
  {
    path: '/processStdPriceView',
    name: 'processStdPriceView',
    component: ProcessStdPriceView
  },
  {
    path: '/processPriceBomView',
    name: 'processPriceBomView',
    component: ProcessPriceBomView
  },
  {
    path: '/pieceRateWageQueryView',
    name: 'pieceRateWageQueryView',
    component: PieceRateWageQueryView
  },
  {
    path: '/pieceRateWageSumView',
    name: 'pieceRateWageSumView',
    component: PieceRateWageSumView
  },
  {
    path: '/changeQtyView',
    name: 'changeQtyView',
    component: ChangeQtyView
  },
  {
    path: '/pltSalesDataView',
    name: 'pltSalesDataView',
    component: SalesDataView
  },
  {
    path: '/pltSalesDataView',
    name: 'pltSalesDataView',
    component: SalesDataView
  },
  {
    path: '/amzSalesOrderView',
    name: 'amzSalesOrderView',
    component: AmzSalesOrderView
  },
  {
    path: '/amzSalesOrderTimeAnalyserView',
    name: 'amzSalesOrderTimeAnalyserView',
    component: AmzSalesOrderTimeAnalyserView
  },
  {
    path: '/pdfViewer',
    name: 'pdfViewer',
    component: PdfViewer
  },
  {
    path: '/customerView',
    name: 'customerView',
    component: CustomerView
  },
  {
    path: '/orderCostView',
    name: 'orderCostView',
    component: OrderCostView
  },
  {
    path: '/activityCostAnalysisView',
    name: 'activityCostAnalysisView',
    component: ActivityCostAnalysisView
  },
  {
    path: '/activityDiscountCostAnalysisView',
    name: 'activityDiscountCostAnalysisView',
    component: ActivityDiscountCostAnalysisView
  },
  {
    path: '/oemSettlingView',
    name: 'oemSettlingView',
    component: OemSettlingView
  },
  {
    path: '/systemAuditView',
    name: 'systemAuditView',
    component: SystemAuditView
  },
  {
    path: '/sewingProcessTypeView',
    name: 'sewingProcessTypeView',
    component: SewingProcessTypeView
  },
  {
    path: '/sewingProcessView',
    name: 'sewingProcessView',
    component: SewingProcessView
  },
  {
    path: '/sewingProcessBomView',
    name: 'sewingProcessBomView',
    component: SewingProcessBomView
  },
  {
    path: '/sewingProcessPriceCheckView',
    name: 'sewingProcessPriceCheckView',
    component: SewingProcessPriceCheckView
  },
  {
    path: '/payCycleView',
    name: 'payCycleView',
    component: PayCycleView
  },
  {
    path: '/stylePrefixView',
    name: 'stylePrefixView',
    component: StylePrefixView
  },
  {
    path: '/importantActivityView',
    name: 'importantActivityView',
    component: ImportantActivityView
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.path == '/') return next();
  const token = window.sessionStorage.getItem('token');
  if (!token) return next('/');
  return next();
})

export default router
