// 引入axios封装
import axios from 'axios'
import { ElMessage, ElMessageBox } from 'element-plus'
import router from '@/router';
import { COMMON_URL } from '../../public/IP'

const BASE_URL = COMMON_URL + '/bls/prod/wsProcessType/'

function save(data: any) {
    const saveUrl = BASE_URL + "save";
    console.log(saveUrl);
    axios({
        method: "post",
        url: saveUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data
    }).then(respone => {
        ElMessageBox.alert(respone.data, '提示信息')
    }).catch(reason => {
        ElMessageBox.alert(reason.data, '提示信息')
    })
}

function query(condition: any, result: any, totalQty: any, addData: any) {
    const queryUrl = BASE_URL + "query";
    console.log(queryUrl)
    axios({
        method: "post",
        url: queryUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data: condition
    }).then(respone => {
        if (respone.data.errorCode == "111111") {
            ElMessageBox.alert(respone.data.errorMsg, '提示信息')
            return;
        }
        result.value = respone.data.result;
        totalQty.total = result.value.length

        if (respone.data.result.length > 0) {
            addData.id = respone.data.result[0].id;
            addData.processTypeNo = respone.data.result[0].processTypeNo;
            addData.processType = respone.data.result[0].processType;
            addData.processNo = respone.data.result[0].processNo;
            addData.remark = respone.data.result[0].remark;
            addData.sn = respone.data.result[0].sn;
        } else {
            addData.id = null;
            addData.processTypeNo = null;
            addData.processType = null;
            addData.processNo = null;
            addData.remark = null;
            addData.sn = null;
        }
    }).catch(reason => {
        console.log(reason);
    })
}

function queryProcess(result: any) {
    const queryUrl = BASE_URL + "queryAllSewingProcessType";
    console.log(queryUrl)
    axios({
        method: "post",
        url: queryUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        }
    }).then(respone => {
        if (respone.data.errorCode == "111111") {
            ElMessageBox.alert(respone.data.errorMsg, '提示信息')
            return;
        }
        result.value = respone.data;
    }).catch(reason => {
        console.log(reason);
    })
}

function deleteData(data: any) {
    const deleteUrl = BASE_URL + "delete";
    console.log(deleteUrl);
    axios({
        method: "post",
        url: deleteUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded'
        },
        data
    }).then(respone => {
        ElMessageBox.alert(respone.data, '提示信息')
    }).catch(reason => {
        ElMessageBox.alert(reason.data, '提示信息')
    })
}

export { save, deleteData, query, queryProcess }